import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllArticlePageProps } from '~/types/common-props'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Company from '~/views/Company/Company.view'

const CompanyPage = () => <Company />

export default CompanyPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Company" }) {
      ...MetaTagInformationPages
    }
  }
`

export const Head = ({ data }: AllArticlePageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/company/"
    />
  )
}
